<template>
  <span class="product-city">
    Доставка в г.
    <span @click="isAddDelivery = true">{{ addressDelivery }}</span>
  </span>
  <span class="product-delivery-item" v-if="deliveryPoint">
    До ПВЗ: {{ deliveryPoint }}
  </span>
  <span class="product-delivery-item" v-if="deliveryCourier">
    Курьер: {{ deliveryCourier }}
  </span>
  <span
    v-if="!deliveryPoint && !deliveryCourier && !isLoaded"
    class="product-delivery-item"
  >
    доставка не осуществляется
  </span>
  <AddDeliveryPopupBlock
    v-if="isAddDelivery"
    :deliveriesPoints="deliveriesPoints"
    :isCreate="true"
    :isSettings="false"
    :isCheckout="false"
    @tooltipDelivery="tooltipDelivery = $event"
    @changeCity="city = $event"
    @changeAddress="addressDelivery = $event"
  />
  <SuccessTooltipBlock
    v-if="tooltipDelivery"
    :header="'Адрес сохранен по умолчанию'"
  />
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import AddDeliveryPopupBlock from "@/components/AddDeliveryPopupBlock.vue";
import SuccessTooltipBlock from "@/components/SuccessTooltipBlock.vue";
import api from "@/api";
import Locale from "@/api/locale";

export default {
  mixins: [mainMixin],
  components: {
    AddDeliveryPopupBlock,
    SuccessTooltipBlock,
  },
  data() {
    return {
      city: null,
      addressDelivery: null,
      isLoaded: true,
      deliveryPoint: null,
      deliveryCourier: null,
      isAddDelivery: false,
      deliveriesPoints: [],
      tooltipDelivery: false,
    };
  },
  watch: {
    addressDelivery(value) {
      if (this.city === value) return false;
      if (value) localStorage.setItem("addressSelected", value);
      else {
        localStorage.removeItem("addressSelected");
        this.addressDelivery = this.city;
      }
    },
    async city(newCity) {
      localStorage.setItem("city", newCity);
      this.$emit("changeCity", newCity);
      this.deliveryPoint = null;
      this.deliveryCourier = null;
      this.isLoaded = true;
      try {
        const result = await api.shop.getDeliveryInfoFromCity(newCity);
        if (result["data"]["point_from"]) {
          this.deliveryPoint = this.dateFormat(result["data"]["point_from"]);
          if (result["data"]["point_from"] !== result["data"]["point_to"]) {
            this.deliveryPoint += " - ";
            this.deliveryPoint = this.dateFormat(result["data"]["point_to"]);
          }
          this.deliveryPoint += ", бесплатно";
        }
        if (result["data"]["courier_from"]) {
          this.deliveryCourier = this.dateFormat(
            result["data"]["courier_from"]
          );
          if (result["data"]["courier_from"] !== result["data"]["courier_to"]) {
            this.deliveryCourier += " - ";
            this.deliveryCourier = this.dateFormat(
              result["data"]["courier_to"]
            );
          }
          this.deliveryCourier += ", 199 руб.";
        }
        this.isLoaded = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    let that = this;
    document.addEventListener("keydown", function (evt) {
      if (evt.which === 27) {
        that.deliveriesPoints = false;
        that.isOpenPopup(false);
      }
    });
    try {
      const result = await api.shop.getDeliveryPointsMaps();
      this.deliveriesPoints = result["data"]["points"];
    } catch (error) {
      console.log(error);
    }
    this.city = localStorage.getItem("city");
    this.addressDelivery = localStorage.getItem("addressSelected");
    if (!this.addressDelivery) this.addressDelivery = this.city;
  },
  methods: {
    dateFormat(value) {
      let result = "";
      let date = new Date(value);
      let month = date.getMonth();
      result = date.getDate();
      result += " ";
      if (month === 0) result += Locale.TEXTS.monthd1;
      if (month === 1) result += Locale.TEXTS.monthd2;
      if (month === 2) result += Locale.TEXTS.monthd3;
      if (month === 3) result += Locale.TEXTS.monthd4;
      if (month === 4) result += Locale.TEXTS.monthd5;
      if (month === 5) result += Locale.TEXTS.monthd6;
      if (month === 6) result += Locale.TEXTS.monthd7;
      if (month === 7) result += Locale.TEXTS.monthd8;
      if (month === 8) result += Locale.TEXTS.monthd9;
      if (month === 9) result += Locale.TEXTS.monthd10;
      if (month === 10) result += Locale.TEXTS.monthd11;
      if (month === 11) result += Locale.TEXTS.monthd12;
      return result;
    },
  },
};
</script>
