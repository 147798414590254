export default {
  data() {
    return {
      authToken: "",
    };
  },
  mounted() {
    if (window.innerWidth < 1200) {
      setTimeout(function () {
        if (!document.querySelector(".personal-footer")) {
          let nav = document.querySelector("nav");
          if (!nav) return false;
          let block = document.querySelector(".subscriber-section");
          if (!block) block = document.querySelector(".footer-content");
          window.addEventListener("scroll", function () {
            let scrollY = window.innerHeight + window.scrollY;
            if (scrollY > block.offsetTop) {
              nav.classList.add("fixed");
              let top = block.offsetTop - 65;
              nav.style.top = top + "px";
            } else nav.classList.remove("fixed");
          });
        }
      }, 1000);
    }
  },
  created() {
    this.authToken = localStorage.getItem("auth_token");
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let clickHash = localStorage.getItem("clickHash");
    if (urlParams.get("click_hash") && !clickHash) {
      clickHash = urlParams.get("click_hash");
      localStorage.setItem("clickHash", clickHash);
      localStorage.setItem("clickAttribution", String(new Date()));
      sessionStorage.setItem("clickAttributionSession", "1");
    }
    let userHash = localStorage.getItem("userHash");
    if (urlParams.get("user_hash") && !userHash) {
      userHash = urlParams.get("user_hash");
      localStorage.setItem("userHash", userHash);
    }
  },
  methods: {
    updateNav() {
      let nav = document.querySelector("nav");
      let block = document.querySelector(".subscriber-section");
      if (!block) block = document.querySelector(".footer-content");
      let scrollY = window.innerHeight + window.scrollY;
      if (scrollY > block.offsetTop) {
        nav.classList.add("fixed");
        let top = block.offsetTop - 65;
        nav.style.top = top + "px";
      } else nav.classList.remove("fixed");
    },
    isOpenPopup(isOpen) {
      if (isOpen) document.body.style.overflow = "hidden";
      else document.body.style.overflow = "auto";
    },
    nextOnCart() {
      window.location.href = "/cart";
    },
    toFixed(value, count) {
      return value.toFixed(count);
    },
  },
};
