<template>
  <HeaderBlock :isHideSearch="true" />
  <section class="questionnaire-section">
    <a class="page-back" href="/">
      <span>Вернуться на главную</span>
    </a>
    <div class="questionnaire-start">
      <h1>Онлайн-подбор витаминов</h1>
      <p>
        Ответьте на вопросы анкеты и получите автоматические рекомендации в
        зависимости от вашего профиля
      </p>
      <button @click="startQuestionnaire()">Пройти анкету</button>
      <span class="agree">
        Заполняя форму вы соглашаетесь с
        <a href="/policy" target="_blank">политикой конфиденциальности</a>
        и
        <a href="/offerta-affiliate-program" target="_blank">
          условиями партнерской программы
        </a>
      </span>
    </div>
  </section>
  <FooterBlock />
  <AuthRegBlock v-if="isOpenAuth" :authText="authText" />
</template>

<script>
import jsMixin from "@/mixins/jsMixin";
import mainMixin from "@/mixins/mainMixin";
import profileMixin from "@/mixins/profileMixin";
import HeaderBlock from "@/components/HeaderBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import AuthRegBlock from "@/components/AuthRegBlock.vue";
import api from "@/api";
import "@/assets/css/questionnaire.css";

export default {
  mixins: [jsMixin, mainMixin, profileMixin],
  components: {
    HeaderBlock,
    FooterBlock,
    AuthRegBlock,
  },
  data() {
    return {
      questionnaire: null,
      slug: "",
      authToken: String,
      profile: Object,
      isOpenAuth: false,
      authText: "",
    };
  },
  async mounted() {
    try {
      this.slug = this.$route.params.path_questionnaire;
      const result = await api.shop.getQuestionnaireFromPath(this.slug);
      let questionnaire = result["data"]["results"][0];
      if (questionnaire) this.questionnaire = questionnaire;
      else window.location.href = "/";
      document.title = questionnaire["shop_page"][0]["title"];
      document.getElementsByTagName("meta")["description"].content =
        questionnaire["shop_page"][0]["meta_description"];
    } catch (error) {
      window.location.href = "/";
    }
  },
  methods: {
    async startQuestionnaire() {
      if (this.authToken) {
        try {
          let data = {
            questionnaire_id: this.questionnaire.id,
          };
          const result = await api.profile.postStartQuestionnaire(data);
          location.href = "/personal/questionnaire/" + result["data"]["code"];
        } catch (error) {
          console.log(error);
        }
      } else {
        this.isOpenAuth = true;
        this.authText =
          "Войдите или зарегистрируйтесь,<br /> чтобы начать анкету";
      }
    },
  },
};
</script>
