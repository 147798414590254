<template>
  <div class="personal-header">
    <HeaderBlock :isHideSearch="false" />
  </div>
  <section class="personal-section">
    <PersonalLeftBlock :hrefTo="'questionnaire'" />
    <div class="block-right block-questionnaire">
      <a @click="$router.push({ name: 'personal' })" class="back">В профиль</a>
      <h1>Подбор витаминов</h1>
      <div class="returns-add" v-if="questionnaire">
        <div>
          <span>
            Заполните анкету и получите персональные рекомендации по приему
            витаминов <br />
            и минералов на ближайшие 12 месяцев
          </span>
        </div>
        <button @click.stop="openQuestionnaire">Заполнить анкету</button>
      </div>
      <div v-for="item in attempts" :key="item" class="attempt-item">
        <div>
          <a :href="'/personal/questionnaire/' + item.code">
            Анкета {{ item.id }}
          </a>
          <span>от {{ dateFormatString(item.created_at) }}</span>
        </div>
        <span v-if="item.status === 1">Анкета не заполнена</span>
        <span v-else>Анкета заполнена</span>
        <a
          v-if="item.status === 1"
          :href="'/personal/questionnaire/' + item.code"
        >
          Продолжить анкету
        </a>
        <a v-else class="gray" :href="'/personal/questionnaire/' + item.code">
          Посмотреть результаты
        </a>
      </div>
    </div>
  </section>
  <div class="personal-footer">
    <FooterBlock />
  </div>
</template>

<script>
import jsMixin from "@/mixins/jsMixin";
import mainMixin from "@/mixins/mainMixin";
import pageMixin from "@/mixins/pageMixin";
import profileMixin from "@/mixins/profileMixin";
import HeaderBlock from "@/components/HeaderBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import PersonalLeftBlock from "@/components/PersonalLeftBlock.vue";
import "@/assets/css/personal.css";
import api from "@/api";

export default {
  mixins: [jsMixin, mainMixin, pageMixin, profileMixin],
  components: {
    HeaderBlock,
    FooterBlock,
    PersonalLeftBlock,
  },
  data() {
    return {
      questionnaire: null,
      attempts: [],
    };
  },
  async created() {
    document.title = "Подбор витаминов";
    let data = {
      is_default: true,
    };
    try {
      let result = await api.shop.getQuestionnaires(data);
      result = result["data"]["results"];
      if (result.length > 0) this.questionnaire = result[0];
    } catch (error) {
      console.log(error);
    }
    try {
      const result = await api.profile.getQuestionnaireAttempts();
      this.attempts = result["data"]["results"];
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    openQuestionnaire() {
      window.open(
        "/questionnaire/" + this.questionnaire["shop_page"][0]["url"]
      );
    },
  },
};
</script>
