<template>
  <CatalogPage
    :productCategory="productCategory"
    :profile="profile"
    :slug="slug"
  />
</template>

<script>
import jsMixin from "@/mixins/jsMixin";
import mainMixin from "@/mixins/mainMixin";
import profileMixin from "@/mixins/profileMixin";
import CatalogPage from "@/pages/CatalogPage.vue";
import Locale from "@/api/locale";

export default {
  mixins: [jsMixin, mainMixin, profileMixin],
  components: {
    CatalogPage,
  },
  data() {
    return {
      productCategory: {
        name: Locale.TEXTS.catalogAllProducts,
        id: null,
      },
      slug: "catalog",
    };
  },
  created() {
    document.title = Locale.TEXTS.catalogTitle;
  },
};
</script>
