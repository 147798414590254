<template>
  <div class="profile-name-popup survey-popup shop-popup">
    <div class="content">
      <img
        @click="$parent.isOpenSurvey = false"
        src="@/assets/images/shop-close.svg"
        class="close"
      />
      <h5>Заполните анкету</h5>
      <div class="select-specialization" @click="clickOpenSpecialization">
        <small>Специализация</small>
        <span>{{ specialization }}</span>
        <div :class="{ open: isOpenSpecialization }">
          <span @click="specialization = 'Врач'">Врач</span>
          <span @click="specialization = 'Нутрициолог'">Нутрициолог</span>
          <span @click="specialization = 'Health-коуч'">Health-коуч</span>
          <span @click="specialization = 'Диетолог'">Диетолог</span>
          <span @click="specialization = 'Тренер'">Тренер</span>
          <span @click="specialization = 'Инфлюенсер'">Инфлюенсер</span>
          <span @click="specialization = 'Блогер'">Блогер</span>
          <span @click="specialization = 'Другое'">Другое</span>
        </div>
      </div>
      <div class="group-input">
        <small>Телеграм</small>
        <input
          type="text"
          placeholder="Телеграм"
          ref="telegram"
          v-model="telegram"
        />
      </div>
      <div class="group-input">
        <small>Инстаграм*</small>
        <input type="text" placeholder="Инстаграм" v-model="instagram" />
      </div>
      <div class="group-input">
        <small>Vk*</small>
        <input type="text" placeholder="Vk" v-model="vk" />
      </div>
      <div class="group-input">
        <small>Комментарий*</small>
        <input type="text" placeholder="Комментарий" v-model="comment" />
      </div>
      <button @click.stop="setSurvey">Сохранить анкету</button>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  props: {
    profile: Object,
  },
  data() {
    return {
      telegram: "",
      instagram: "",
      vk: "",
      comment: "",
      isOpenSpecialization: false,
      specialization: "Другое",
    };
  },
  created() {
    let that = this;
    setTimeout(function () {
      that.$refs["telegram"].focus();
    }, 100);
  },
  mounted() {
    this.telegram = this.profile.telegram;
    this.instagram = this.profile.instagram;
    this.vk = this.profile.vk;
    this.comment = this.profile.comment;
    let that = this;
    document.addEventListener("keydown", function (evt) {
      if (evt.which === 27) {
        that.$parent.isOpenSurvey = false;
      }
    });
  },
  methods: {
    clickOpenSpecialization() {
      if (this.isOpenSpecialization) this.isOpenSpecialization = false;
      else this.isOpenSpecialization = true;
    },
    async setSurvey() {
      try {
        let payload = {
          telegram: this.telegram,
          instagram: this.instagram,
          vk: this.vk,
          comment: this.comment,
          specialization: this.specialization,
        };
        const result = await api.auth.editProfile(payload);
        localStorage.setItem("profile_telegram", this.telegram);
        localStorage.setItem("profile_instagram", this.instagram);
        localStorage.setItem("profile_vk", this.vk);
        localStorage.setItem("profile_comment", this.comment);
        this.$parent.isOpenSurvey = false;
        if (result["data"]["is_partner"]) location.href = "/personal/partner";
        else this.$parent.isOpenThank = true;
      } catch (error) {
        console.log(error);
      }
      return false;
    },
  },
};
</script>
