<template>
  <div class="questions-header">
    <HeaderBlock :isHideSearch="false" />
  </div>
  <section class="questions-section page-section">
    <a class="page-back" :href="'/catalog/' + slug">
      <span>{{ product.headline_preview }}</span>
    </a>
    <h1>{{ locale.TEXTS.questionsH1 }} {{ product.headline_preview }}</h1>
    <div class="page-content">
      <div class="page-left">
        <div class="product-question">
          <span class="count">{{ countQuestions }}</span>
          <span class="text">
            <span v-html="locale.TEXTS.countQuestionsText"></span>
          </span>
          <button v-if="locale.LOCALE === 'ru'" @click="openAddQuestionBlock">
            Задать вопрос
          </button>
        </div>
        <div
          class="product-question-item"
          v-for="item in questions"
          :key="item"
          :class="{ open: item.isOpen }"
        >
          <span
            class="button"
            @click="item.isOpen ? (item.isOpen = false) : (item.isOpen = true)"
          ></span>
          <span
            @click="item.isOpen ? (item.isOpen = false) : (item.isOpen = true)"
            class="text"
          >
            {{ item.text }}
          </span>
          <p>{{ item.answer }}</p>
        </div>
      </div>
      <ProductInfoPageRightBlock
        v-if="product.id"
        :product="product"
        :info="'question'"
      />
    </div>
  </section>
  <FooterBlock />
  <AuthRegBlock
    v-if="isOpenAuth && locale.LOCALE === 'ru'"
    :authText="authText"
  />
  <ProfileNameBlock
    v-if="isOpenProfileName"
    :profile="profile"
    :isCheckout="false"
    :isPartner="false"
    :isLastName="false"
    :isDisplayEmail="true"
  />
  <AddQuestionBlock
    v-if="isOpenAddQuestion && locale.LOCALE === 'ru'"
    :product="product"
  />
</template>

<script>
import jsMixin from "@/mixins/jsMixin";
import mainMixin from "@/mixins/mainMixin";
import profileMixin from "@/mixins/profileMixin";
import productMixin from "@/mixins/productMixin";
import HeaderBlock from "@/components/HeaderBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import AuthRegBlock from "@/components/AuthRegBlock.vue";
import ProfileNameBlock from "@/components/ProfileNameBlock.vue";
import AddQuestionBlock from "@/components/AddQuestionBlock.vue";
import ProductInfoPageRightBlock from "@/components/ProductInfoPageRightBlock.vue";
import api from "@/api";
import Locale from "@/api/locale";

export default {
  mixins: [jsMixin, mainMixin, profileMixin, productMixin],
  components: {
    HeaderBlock,
    FooterBlock,
    AuthRegBlock,
    ProfileNameBlock,
    AddQuestionBlock,
    ProductInfoPageRightBlock,
  },
  data() {
    return {
      isOpenAuth: false,
      isOpenProfileName: false,
      isOpenAddQuestion: false,
      product: Object,
      slug: "",
      countQuestions: 0,
      questions: [],
      locale: Locale,
    };
  },
  async mounted() {
    try {
      this.slug = this.$route.params.path_catalog;
      const result = await api.shop.getShopPageFromPath(this.slug);
      let page = result["data"]["results"][0];
      if (page["product"]) this.product = page["product"];
      else window.location.href = "/catalog";
      document.title = page["title"];
      document.getElementsByTagName("meta")["description"].content =
        page["description"];
    } catch (error) {
      window.location.href = "/catalog";
    }
    try {
      const result = await api.shop.getProductQuestions(this.product.id);
      this.countQuestions = result["data"]["results"].length;
      for (let item in result["data"]["results"]) {
        item = result["data"]["results"][item];
        this.questions.push({
          text: item["text"],
          answer: item["answer_text"],
          isOpen: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
    if (window.innerWidth < 1200) {
      window.addEventListener("scroll", function () {
        let basket = document.querySelector(".questions-header");
        if (!basket) return false;
        let price = document.querySelector(".product-basket .price");
        let top = document.querySelector(".subscriber-section");
        top = top.offsetTop - window.innerHeight - 40;
        if (window.scrollY > top) {
          price.classList.remove("fixed");
        } else {
          price.classList.add("fixed");
        }
      });
    } else {
      window.addEventListener("scroll", function () {
        let basket = document.querySelector(".product-basket");
        if (!basket) return false;
        let menu = document.querySelector(".page-content");
        let top = document.querySelector(".subscriber-section");
        top = top.offsetTop - window.innerHeight / 2;
        if (window.scrollY > top) {
          menu.classList.remove("fixed");
          basket.classList.remove("fixed");
        } else if (window.scrollY > menu.offsetTop - 35) {
          menu.classList.add("fixed");
          basket.classList.add("fixed");
        } else if (menu.classList.contains("fixed")) {
          menu.classList.remove("fixed");
          basket.classList.remove("fixed");
        }
      });
    }
    let that = this;
    document.addEventListener("keydown", function (evt) {
      if (evt.which === 27) {
        that.isOpenAuth = false;
        that.isOpenProfileName = false;
        that.isOpenAddQuestion = false;
        that.isOpenPopup(false);
      }
    });
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get("is-auth")) {
      history.pushState(
        null,
        null,
        location.protocol + "//" + location.host + location.pathname
      );
      if (localStorage.getItem("product_favorite")) {
        this.clickFavoriteProduct(this.product);
        localStorage.removeItem("product_favorite");
      } else this.openAddQuestionBlock();
    }
    this.getFavoriteProduct(this.product);
    this.getBasketProduct(this.product);
  },
  watch: {
    isOpenAuth(isOpen) {
      this.isOpenPopup(isOpen);
    },
    isOpenProfileName(isOpen) {
      this.isOpenPopup(isOpen);
      if (window.innerWidth < 800) {
        setTimeout(function () {
          document.querySelector(".profile-name-popup button").innerHTML =
            "Сохранить";
        }, 100);
      }
    },
    isOpenAddQuestion(isOpen) {
      this.isOpenPopup(isOpen);
      if (window.innerWidth < 800) {
        setTimeout(function () {
          document.querySelector(".add-question-popup button").innerHTML =
            "Отправить";
        }, 100);
      }
    },
  },
};
</script>
