<template>
  <div class="personal-header">
    <HeaderBlock :isHideSearch="false" />
  </div>
  <section class="personal-section">
    <PersonalLeftBlock :hrefTo="'products'" />
    <div class="block-right personal-products">
      <a @click="$router.push({ name: 'personal' })" class="back">В профиль</a>
      <h1>Мои товары</h1>
      <div class="destination-tabs">
        <span :class="{ active: tabValue === 0 }" @click.stop="tabValue = 0">
          Купленные товары
          <span>{{ purchases.length }}</span>
        </span>
        <span :class="{ active: tabValue === 1 }" @click.stop="tabValue = 1">
          Избранные товары
          <span>{{ favorites.length }}</span>
        </span>
      </div>
      <div v-if="purchases.length > 0 && tabValue === 0" class="products-items">
        <ProductItemBlock
          v-for="item in purchases"
          :product="item"
          :key="item"
          :isFavorite="true"
          :isBasket="false"
          :type="''"
          :sourcePage="10"
        />
      </div>
      <div v-if="favorites.length > 0 && tabValue === 1" class="products-items">
        <ProductItemBlock
          v-for="item in favorites"
          :product="item"
          :key="item"
          :isFavorite="false"
          :isBasket="false"
          :type="''"
          :sourcePage="8"
        />
      </div>
    </div>
  </section>
  <div class="personal-footer">
    <FooterBlock />
  </div>
</template>

<script>
import jsMixin from "@/mixins/jsMixin";
import mainMixin from "@/mixins/mainMixin";
import profileMixin from "@/mixins/profileMixin";
import HeaderBlock from "@/components/HeaderBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import PersonalLeftBlock from "@/components/PersonalLeftBlock.vue";
import ProductItemBlock from "@/components/ProductItemBlock.vue";
import "@/assets/css/personal.css";
import api from "@/api";

export default {
  mixins: [jsMixin, mainMixin, profileMixin],
  components: {
    HeaderBlock,
    FooterBlock,
    PersonalLeftBlock,
    ProductItemBlock,
  },
  data() {
    return {
      tabValue: 0,
      purchases: [],
      favorites: [],
    };
  },
  async created() {
    document.title = "Мои продукты";
    try {
      const result = await api.shop.getShopProductUserPurchases();
      this.purchases = result["data"]["results"];
    } catch (error) {
      console.log(error);
    }
    try {
      const result = await api.shop.getShopProductUserFavorites();
      for (let item in result["data"]["results"]) {
        item = result["data"]["results"][item]["product"];
        // item.isDisplayFavorite = true;
        item.isDisplayFavorite = false;
        item.isFavorite = "active";
        this.favorites.push(item);
      }
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
