<template>
  <div class="page-right">
    <div class="product-basket">
      <span
        v-if="locale.LOCALE === 'ru'"
        class="favorite"
        @click.stop="clickFavoriteProduct(product)"
        :class="
          'favorite-' +
          info +
          '-' +
          product.id +
          ' favorite-' +
          product.id +
          ' ' +
          product.isFavorite
        "
      ></span>
      <div class="price">
        <span class="new">
          {{ formatPrice(productPrice) }}
          {{ locale.TEXTS.currencyText }}
        </span>
        <span v-if="productPriceOld" class="old">
          {{ formatPrice(productPriceOld) }}
          {{ locale.TEXTS.currencyText }}
        </span>
        <button v-if="product.productBasket > 0" @click="nextOnCart">
          <span>В корзине {{ product.productBasket }} шт.</span>
          <span>Перейти</span>
        </button>
        <button
          v-else
          @click.stop="clickBasketProduct(product, 1, true, sourcePage, null)"
          :class="'basket-' + info + '-' + product.id + ' basket-' + product.id"
        >
          {{ locale.TEXTS.toBasketText }}
        </button>
      </div>
      <span v-if="productPriceOld" class="benefit">
        {{ locale.TEXTS.yourBenefit }}
        {{ productPercent }} % ({{ formatPrice(productAmount) }}
        {{ locale.TEXTS.currencyText }})
      </span>
      <span v-if="product.bonus > 0" class="benefit">
        + {{ product.bonus }} {{ getTextBonus(product.bonus) }}
        {{ locale.TEXTS.bonusBuy }}
      </span>
      <div v-if="product.productBasket > 0" class="product-basket-active">
        <span
          class="minus"
          @click.stop="
            clickBasketProduct(
              product,
              product.productBasket - 1,
              false,
              sourcePage,
              null
            )
          "
        ></span>
        <button @click="nextOnCart">
          <span>В корзине {{ product.productBasket }} шт.</span>
          <span>Перейти</span>
        </button>
        <span
          class="plus"
          @click.stop="
            clickBasketProduct(
              product,
              product.productBasket + 1,
              true,
              sourcePage,
              null
            )
          "
          :class="'basket-' + info + '-' + product.id + ' basket-' + product.id"
        ></span>
      </div>
      <button
        v-else
        @click.stop="clickBasketProduct(product, 1, true, sourcePage, null)"
        :class="'basket-' + info + '-' + product.id + ' basket-' + product.id"
      >
        {{ locale.TEXTS.addBasketText }}
      </button>
    </div>
  </div>
  <AuthRegBlock v-if="isOpenAuth" :authText="authText" />
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import productMixin from "@/mixins/productMixin";
import AuthRegBlock from "@/components/AuthRegBlock.vue";
import Locale from "@/api/locale";
import api from "@/api";

export default {
  mixins: [mainMixin, productMixin],
  props: {
    product: Object,
    info: String,
  },
  components: {
    AuthRegBlock,
  },
  data() {
    return {
      productPercent: 0,
      productAmount: 0,
      productPrice: null,
      productPriceOld: null,
      isOpenAuth: false,
      locale: Locale,
      sourcePage: 1,
    };
  },
  async mounted() {
    if (this.info === "feedback") this.sourcePage = 2;
    if (this.info === "question") this.sourcePage = 3;
    this.productPrice = this.product.website_price;
    if (this.product.discount_price) {
      this.productPrice = this.product.discount_price;
      this.productPriceOld = this.product.website_price;
    }
    if (this.productPriceOld) {
      this.productAmount = this.productPriceOld - this.productPrice;
      this.productPercent = this.productPrice * 100;
      this.productPercent = this.productPercent / this.productPriceOld;
      this.productPercent = Math.round(100 - this.productPercent);
    }
    let that = this;
    document.addEventListener("keydown", function (evt) {
      if (evt.which === 27) {
        that.isOpenAuth = false;
      }
    });
    let promoCode = localStorage.getItem("tracker_promoCode");
    try {
      const result = await api.shop.checkPromoCodeProduct(
        promoCode,
        this.product.id
      );
      if (result["data"]["percent"] > 0) {
        this.trackerPromoCode = promoCode;
        this.trackerPromoCodePercent = result["data"]["percent"];
        let price = this.product.discount_price;
        if (!price) price = this.product.website_price;
        price = price - result["data"]["amount"];

        this.productPrice = price;
        this.productPriceOld = this.product.website_price;

        this.productAmount = this.productPriceOld - this.productPrice;
        this.productPercent = this.productPrice * 100;
        this.productPercent = this.productPercent / this.productPriceOld;
        this.productPercent = Math.round(100 - this.productPercent);
        localStorage.setItem("cart_promoCode", promoCode);
      }
    } catch (error) {
      console.log(error);
    }
  },
  watch: {
    isOpenAuth(isOpen) {
      this.isOpenPopup(isOpen);
    },
  },
};
</script>
