<script>
import jsMixin from "@/mixins/jsMixin";

export default {
  mixins: [jsMixin],
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.getTrackerClick(urlParams, this.$route.params.path_stream, true);
  },
};
</script>
