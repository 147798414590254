<template>
  <HeaderBlock :isHideSearch="true" />
  <section class="questionnaire-section" v-if="attempt">
    <a class="page-back" href="/" v-if="question">
      <span>Вернуться на главную</span>
    </a>
    <div class="questionnaire-question" v-if="question">
      <div class="question-value">{{ question.value }}</div>
      <div v-if="question.isCheckbox" class="question-description">
        можно выбрать несколько вариантов
      </div>
      <div
        class="answers"
        v-if="question.answers"
        :class="{
          'answers-with-checkbox': question.isCheckbox,
          'answers-with-images': question.isImages,
        }"
      >
        <div
          v-for="item in question.answers"
          :key="item"
          class="answer-value"
          :class="{ active: item.isChecked }"
          @click.stop="clickAnswer(item)"
        >
          <img
            v-if="question.isImages && item.image"
            :src="item.image.image_object"
          />
          <span>{{ item.value }}</span>
        </div>
      </div>
      <div class="buttons">
        <button v-if="question.isShowButton" @click.stop="clickAnswer()">
          Продолжить
        </button>
        <span v-if="question.parentsId.length > 0">
          <a @click.stop="clickPrevQuestion()">Предыдущий вопрос</a>
        </span>
      </div>
    </div>
    <div v-if="attempt.status === 2" class="attempt-result">
      <h1>Результат подбора</h1>
      <h2>персональный рецепт № {{ attempt.id }}</h2>
      <div class="attempt-recipes">
        <div v-for="item in recipes" :key="item" class="attempt-recipe">
          <h4 v-if="item.type === 1">Утром до еды</h4>
          <h4 v-if="item.type === 2">Утром во время еды</h4>
          <h4 v-if="item.type === 3">Утром после еды</h4>
          <h4 v-if="item.type === 4">Днем во время</h4>
          <h4 v-if="item.type === 5">Днем, через 1 час после еды</h4>
          <h4 v-if="item.type === 6">Вечером, перед сном</h4>
          <div class="attempt-recipe-header">
            <span v-for="month in months" :key="month">{{ month }}</span>
          </div>
          <div class="attempt-recipe-items">
            <div
              class="attempt-recipe-item"
              v-for="product in item.products"
              :key="product"
              :class="{ checked: product.isChecked }"
            >
              <div class="left">
                <label>
                  <input
                    type="checkbox"
                    v-model="product.isChecked"
                    @change="calculationOfOrder()"
                  />
                  <div class="text">{{ item.name }}</div>
                </label>
                <img :src="product.product.photo.image_object" />
              </div>
              <div class="right">
                <span class="month"></span>
                <span class="month"></span>
                <span class="month"></span>
                <span class="month"></span>
                <span class="month"></span>
                <span class="month"></span>
                <span class="month"></span>
                <span class="month"></span>
                <span class="month"></span>
                <span class="month"></span>
                <span class="month"></span>
                <span class="month"></span>
                <span class="month"></span>
                <span class="line" :style="{ left: lineLeft + 'px' }"></span>
                <div class="period-items">
                  <div
                    class="period-item"
                    v-for="period in product.periods"
                    :key="period"
                    :style="{ width: period.width, left: period.left }"
                    :class="{
                      'radius-left': getRadiusLeft(product.periods, period),
                      'radius-right': getRadiusRight(product.periods, period),
                    }"
                  >
                    {{ period.number_of_capsules }}
                    {{ getPeriodDeclination(product, period) }}
                    <span v-if="period.footnotes.length > 0">
                      {{ getPeriodFootnotes(period) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="attempt-recipe-footnotes"
            v-if="getFootnotes(item)"
            v-html="getFootnotes(item)"
          ></div>
        </div>
      </div>
      <div class="attempt-total">
        <div class="tabs">
          <div
            class="tab-item"
            :class="{ active: totalTab === 1 }"
            @click="changeTotalTab(1)"
          >
            <span class="checkbox"></span>
            <span class="text">На ближайшие 2 месяца</span>
            <span class="discount" v-if="totalTabDiscount1">
              -{{ totalTabDiscount1 }}%
            </span>
          </div>
          <div
            class="tab-item"
            :class="{ active: totalTab === 2 }"
            @click="changeTotalTab(2)"
          >
            <span class="checkbox"></span>
            <span class="text">На 6 месяцев</span>
            <span class="discount" v-if="totalTabDiscount2">
              -{{ totalTabDiscount2 }}%
            </span>
          </div>
          <div
            class="tab-item"
            :class="{ active: totalTab === 3 }"
            @click="changeTotalTab(3)"
          >
            <span class="checkbox"></span>
            <span class="text">На 12 месяцев</span>
            <span class="discount" v-if="totalTabDiscount3">
              -{{ totalTabDiscount3 }}%
            </span>
          </div>
        </div>
        <div class="product-basket">
          <div class="left">
            <div class="price">
              <span class="new">{{ formatPrice(totalAmount) }} руб.</span>
              <span class="old">{{ formatPrice(totalAmountBefore) }} руб.</span>
            </div>
            <span class="benefit">
              Вы экономите {{ totalDiscountPercent }} % ({{
                totalDiscountAmount
              }}
              руб.)
            </span>
            <span class="benefit">
              + {{ totalBonus }} бонусов на следующую покупку
            </span>
          </div>
          <button @click.stop="nextOnCheckout()">Оформить заказ</button>
        </div>
        <span class="benefit-mobile">
          Вы экономите {{ totalDiscountPercent }} % ({{ totalDiscountAmount }}
          руб.)
        </span>
        <span class="benefit-mobile">
          + {{ totalBonus }} бонусов на следующую покупку
        </span>
      </div>
    </div>
  </section>
  <FooterBlock />
</template>

<script>
import jsMixin from "@/mixins/jsMixin";
import mainMixin from "@/mixins/mainMixin";
import profileMixin from "@/mixins/profileMixin";
import productMixin from "@/mixins/productMixin";
import HeaderBlock from "@/components/HeaderBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import api from "@/api";
import "@/assets/css/questionnaire.css";

export default {
  mixins: [jsMixin, mainMixin, profileMixin, productMixin],
  components: {
    HeaderBlock,
    FooterBlock,
  },
  data() {
    return {
      slug: null,
      attempt: null,
      questionnaire: null,
      question: null,
      blocks: [],
      stories: {},
      recipeDetail: null,
      recipes: {},
      months: [],
      lineLeft: 72,
      startMonth: 1,
      startDay: 1,
      pastDays: 1,
      totalTab: 1,
      totalTabDiscount1: 0,
      totalTabDiscount2: 0,
      totalTabDiscount3: 0,
      totalAmount: 0,
      totalAmountBefore: 0,
      totalDiscountPercent: 0,
      totalDiscountAmount: 0,
      totalBonus: 0,
    };
  },
  async mounted() {
    try {
      this.slug = this.$route.params.path_attempt;
      const result = await api.profile.getQuestionnaireAttempt(this.slug);
      let attempt = result["data"]["results"][0];
      if (attempt) this.attempt = attempt;
      else window.location.href = "/";
      this.questionnaire = attempt["questionnaire"];
      document.title = this.questionnaire["shop_page"][0]["title"];
      document.getElementsByTagName("meta")["description"].content =
        this.questionnaire["shop_page"][0]["meta_description"];
      for (let item in attempt["stories"]) {
        item = attempt["stories"][item];
        this.stories[item["question_id"]] = item["answers"];
      }
      this.generationQuestionnaire();
      this.getCurrentQuestion();
    } catch (error) {
      window.location.href = "/";
    }
    let this_date = new Date();
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("month")) {
      this.startMonth = Number(urlParams.get("month"));
    } else this.startMonth = 1 + this_date.getMonth();
    let start_day = this_date.getDate();
    if (urlParams.get("day")) {
      start_day = Number(urlParams.get("day"));
    }
    for (let i = this.startMonth; i <= 13 + this.startMonth; i++) {
      let n = i;
      let text = "";
      if (i > 13) n = i - 13;
      if (n === 1) text = "ЯНВ";
      if (n === 2) text = "ФЕВ";
      if (n === 3) text = "МАР";
      if (n === 4) text = "АПР";
      if (n === 5) text = "МАЙ";
      if (n === 6) text = "ИЮН";
      if (n === 7) text = "ИЮЛ";
      if (n === 8) text = "АВГ";
      if (n === 9) text = "СЕН";
      if (n === 10) text = "ОКТ";
      if (n === 11) text = "НОЯ";
      if (n === 12) text = "ДЕК";
      if (text !== "") this.months.push(text);
    }
    this.pastDays = this.getPastDays(this.startMonth, start_day);
    this.startDay = this.getMaxDay(this.startMonth) + 1;
    if (start_day <= 5) {
      this.lineLeft = 24;
      this.startDay = 10;
    } else if (start_day <= 15) {
      this.lineLeft = 48;
      this.startDay = 20;
    }
    this.startDay = this.pastDays - start_day + this.startDay;
  },
  methods: {
    generationQuestionnaire() {
      let answers = {};
      for (let item in this.questionnaire.blocks) {
        item = this.questionnaire.blocks[item];
        if (item["type"] === 2 && item["parents"].length > 0) {
          if (!answers[item["parents"][0]]) {
            answers[item["parents"][0]] = [];
          }
          answers[item["parents"][0]].push({
            id: item["id"],
            value: item["value"],
            image: item["image"],
            isChecked: false,
          });
        }
      }

      for (let item in this.questionnaire.blocks) {
        item = this.questionnaire.blocks[item];
        if (item["type"] === 1) {
          let isImages = true;
          for (let answer in answers[item["id"]]) {
            if (!answers[item["id"]][answer]["image"]) {
              isImages = false;
              break;
            }
          }
          let parentsId = [];
          for (let block in item["parents"]) {
            parentsId.push(item["parents"][block]);
          }
          this.blocks.push({
            id: item["id"],
            value: item["value"],
            isCheckbox: item["is_several_options"],
            isImages: isImages,
            answers: answers[item["id"]],
            parentsId: parentsId,
            isShowButton: false,
          });
        }
      }
    },
    clickAnswer(answer = null) {
      if (!answer) {
        this.stories[this.question.id] = [];
        for (let item in this.question.answers) {
          if (this.question.answers[item].isChecked) {
            this.stories[this.question.id].push(this.question.answers[item].id);
          }
        }
        this.getCurrentQuestion();
        this.saveStory(this.question.id, this.stories[this.question.id]);
        return false;
      }
      this.question.isShowButton = false;
      if (!this.question.isCheckbox) {
        for (let item in this.question.answers) {
          this.question.answers[item].isChecked = false;
        }
        answer.isChecked = true;
        this.question.isShowButton = true;
      } else {
        if (answer.isChecked) answer.isChecked = false;
        else {
          answer.isChecked = true;
          this.question.isShowButton = true;
        }
      }
      if (!this.question.isCheckbox) {
        this.stories[this.question.id] = [answer.id];
        this.getCurrentQuestion(answer.id);
        this.saveStory(this.question.id, [answer.id]);
      }
    },
    clickPrevQuestion() {
      let parent = null;
      for (let item in this.blocks) {
        if (parent) break;
        if (this.question.parentsId.includes(this.blocks[item].id)) {
          parent = this.blocks[item];
        } else {
          for (let answer in this.blocks[item].answers) {
            let answerId = this.blocks[item].answers[answer].id;
            if (this.question.parentsId.includes(answerId)) {
              parent = this.blocks[item];
            }
          }
        }
      }
      this.question = parent;
    },
    async getCurrentQuestion(answer_id = null) {
      if (this.attempt.status === 2) {
        this.getRecipes();
        return false;
      }
      if (this.question) {
        let parent_id = this.question.id;
        if (answer_id) parent_id = answer_id;
        this.question = null;
        for (let item in this.blocks) {
          if (this.blocks[item].parentsId.includes(parent_id)) {
            this.question = this.blocks[item];
            break;
          }
        }
      } else {
        this.question = null;
        for (let item in this.blocks) {
          if (!this.stories[this.blocks[item].id]) {
            this.question = this.blocks[item];
            break;
          }
        }
      }
      if (!this.question) {
        try {
          await api.profile.postQuestionnaireAttemptCompleted(this.attempt.id);
        } catch (error) {
          console.log(error);
        }
        this.attempt.status = 2;
        this.getCurrentQuestion();
      }
    },
    async saveStory(question_id, answers) {
      let payload = {
        attempt_id: this.attempt.id,
        question_id: question_id,
        answers: answers,
      };
      try {
        await api.profile.postQuestionnaireAttemptStory(payload);
      } catch (error) {
        console.log(error);
      }
    },
    async getRecipes() {
      try {
        const result = await api.profile.postQuestionnaireRecipes(
          this.questionnaire.id
        );
        this.recipeDetail = result["data"]["results"][0];
        for (let item in result["data"]["results"][0]["recipes"]) {
          item = result["data"]["results"][0]["recipes"][item];
          if (item["is_deleted"]) continue;
          if (item["answers"].length === 0) {
            if (!this.recipes[item["type"]]) {
              this.recipes[item["type"]] = {
                type: item["type"],
                products: {},
              };
            }
            this.recipes[item["type"]]["products"][item["product_id"]] = {
              product: item["product"],
              isChecked: true,
              periods: item["periods"],
            };
          }
        }
        for (let item in result["data"]["results"][0]["recipes"]) {
          item = result["data"]["results"][0]["recipes"][item];
          if (item["is_deleted"]) continue;
          if (item["answers"].length === 0) continue;
          for (let question in this.stories) {
            for (let story in this.stories[question]) {
              for (let answer in item["answers"]) {
                if (item["answers"][answer] === this.stories[question][story]) {
                  this.recipes[item["type"]]["products"][item["product_id"]][
                    "periods"
                  ] = item["periods"];
                }
              }
            }
          }
        }
        for (let item in this.recipes) {
          for (let product in this.recipes[item]["products"]) {
            let _product = this.recipes[item]["products"][product];
            if (_product["periods"].length === 0) {
              delete this.recipes[item]["products"][product];
            } else {
              this.recipes[item]["products"][product]["periods"] =
                this.getPeriods(_product["periods"]);
            }
          }
          if (Object.keys(this.recipes[item]["products"]).length === 0) {
            delete this.recipes[item];
          }
        }
        this.calculationOfOrder();
      } catch (error) {
        console.log(error);
      }
    },
    getPastDays(month, thisDay) {
      let result = thisDay;
      for (let i = 1; i <= 12; i++) {
        if (i === month) break;
        result += this.getMaxDay(i);
      }
      return result;
    },
    getMaxDay(month) {
      if (month === 1) return 31;
      if (month === 2) return 28;
      if (month === 3) return 31;
      if (month === 4) return 30;
      if (month === 5) return 31;
      if (month === 6) return 30;
      if (month === 7) return 31;
      if (month === 8) return 31;
      if (month === 9) return 30;
      if (month === 10) return 31;
      if (month === 11) return 30;
      if (month === 12) return 31;
    },
    getPeriods(periods) {
      let results = [];
      for (let period in periods) {
        period = periods[period];
        let start_day = this.getPastDays(period["from_month"], 1);
        let stop_day = this.getPastDays(
          period["to_month"],
          this.getMaxDay(period["to_month"])
        );
        if (start_day >= this.startDay) {
          period["width"] = this.getPeriodWidth(period);
          period["left"] = this.getPeriodLeft(period);
          period["days"] = stop_day - start_day + 1;
          results.push(period);
          continue;
        }
        let days = stop_day - this.startDay + period["shift_days"];
        if (days > period["min_days"]) {
          let month = period["to_month"] - this.startMonth + 1;
          let width = month * 72 - this.lineLeft;
          let shift = (72 / 30) * period["shift_days"];
          width = width + shift;
          if (period["from_month"] === 1 && period["to_month"] === 12) {
            width = 13 * 72 - this.lineLeft;
          }
          period["width"] = width + "px";
          period["left"] = this.getPeriodLeft(period);
          period["days"] = days + 1;
          results.push(period);
        } else {
          let month = 13 - this.startMonth + period["from_month"];
          let left = month * 72 - 72;
          period["left"] = left + "px";
          period["days"] = stop_day - start_day + 1;
          period["width"] = this.getPeriodWidth(period);
          results.push(period);
        }
      }
      return results;
    },
    getPeriodWidth(period) {
      let width = period["to_month"] - period["from_month"];
      if (width < 1) width = 0;
      width = width * 72 + 72;
      return width + "px";
    },
    getPeriodLeft(period) {
      let month = period["from_month"] - this.startMonth + 1;
      let left = month * 72 - 72;
      if (this.lineLeft > left) left = this.lineLeft;
      return left + "px";
    },
    getRadiusLeft(periods, period) {
      let result = false;
      let value = Number(period["left"].split("px")[0]);
      for (let item in periods) {
        if (!periods[item]["left"]) continue;
        let this_left = Number(periods[item]["left"].split("px")[0]);
        this_left += Number(periods[item]["width"].split("px")[0]);
        if (value === this_left) result = true;
      }
      return result;
    },
    getRadiusRight(periods, period) {
      let result = false;
      let value = Number(period["left"].split("px")[0]);
      value += Number(period["width"].split("px")[0]);
      for (let item in periods) {
        if (!periods[item]["left"]) continue;
        let this_left = Number(periods[item]["left"].split("px")[0]);
        if (value === this_left) result = true;
      }
      return result;
    },
    getFootnotes(item) {
      let blocks = "";
      let footnotes = {};
      for (let product in item["products"]) {
        for (let period in item["products"][product]["periods"]) {
          period = item["products"][product]["periods"][period];
          for (let footnote in period["footnotes"]) {
            footnote = period["footnotes"][footnote];
            if (footnotes[footnote["id"]]) continue;
            footnotes[footnote["id"]] = true;
            blocks +=
              "<span>" + footnote["id"] + " - " + footnote["name"] + "</span>";
          }
        }
      }
      return blocks;
    },
    getPeriodFootnotes(period) {
      let text = "";
      for (let item in period["footnotes"]) {
        if (text !== "") text += ", ";
        text += period["footnotes"][item]["id"];
      }
      return text;
    },
    changeTotalTab(value) {
      this.totalTab = value;
      this.calculationOfOrder();
    },
    calculationOfOrder() {
      let products = {};
      for (let recipe in this.recipes) {
        for (let product in this.recipes[recipe]["products"]) {
          product = this.recipes[recipe]["products"][product];
          if (!product.isChecked) continue;
          if (!products[product["product"]["id"]]) {
            products[product["product"]["id"]] = {
              id: product["product"]["id"],
              quantity: Number(product["product"]["feature_product_quantity"]),
              website_price: product["product"]["website_price"],
              discount_price: product["product"]["discount_price"],
              bonus: product["product"]["bonus"],
              count: 0,
            };
          }
          for (let period in product["periods"]) {
            period = product["periods"][period];
            products[product["product"]["id"]]["count"] +=
              period["days"] * period["number_of_capsules"];
          }
        }
      }
      let totals = {
        1: { amount: 0, before: 0, bonus: 0, count: 0 },
        2: { amount: 0, before: 0, bonus: 0, count: 0 },
        3: { amount: 0, before: 0, bonus: 0, count: 0 },
      };
      let productsList = [];
      for (let product in products) {
        product = products[product];
        let price = product["discount_price"];
        if (!price) price = product["website_price"];
        let count1 = product["count"];
        if (product["count"] > 61) count1 = 61;
        count1 = Math.ceil(count1 / product["quantity"]);
        totals[1]["count"] += count1;
        totals[1]["amount"] += Math.round(price * count1);
        totals[1]["bonus"] += Math.round(product["bonus"] * count1);
        totals[1]["before"] += Math.round(product["website_price"] * count1);
        let count2 = product["count"];
        if (product["count"] > 185) count2 = 185;
        count2 = Math.ceil(count2 / product["quantity"]);
        totals[2]["count"] += count2;
        totals[2]["amount"] += Math.round(price * count2);
        totals[2]["bonus"] += Math.round(product["bonus"] * count2);
        totals[2]["before"] += Math.round(product["website_price"] * count2);
        let count3 = Math.ceil(product["count"] / product["quantity"]);
        totals[3]["count"] += count3;
        totals[3]["amount"] += Math.round(price * count3);
        totals[3]["bonus"] += Math.round(product["bonus"] * count3);
        totals[3]["before"] += Math.round(product["website_price"] * count3);
        let quantity = count1;
        let total = Math.round(price * count1);
        if (this.totalTab === 2) {
          quantity = count2;
          total = Math.round(price * count2);
        }
        if (this.totalTab === 3) {
          quantity = count3;
          total = Math.round(price * count3);
        }
        productsList.push({
          id: product["id"],
          quantity: quantity,
          total: total,
        });
      }
      let amount = totals[3]["amount"];
      let before = totals[3]["before"];
      let bonus = totals[3]["bonus"];
      let count = totals[3]["count"];
      if (this.totalTab === 1) {
        amount = totals[1]["amount"];
        before = totals[1]["before"];
        bonus = totals[1]["bonus"];
        count = totals[1]["count"];
      }
      if (this.totalTab === 2) {
        amount = totals[2]["amount"];
        before = totals[2]["before"];
        bonus = totals[2]["bonus"];
        count = totals[2]["count"];
      }
      amount = this.getAmount(amount, before, count);
      this.totalAmountBefore = before;
      this.totalBonus = bonus;
      this.totalDiscountAmount = before - amount;
      this.totalDiscountPercent = Math.ceil(
        (this.totalDiscountAmount * 100) / before
      );
      let data = {
        tab_type: this.totalTab,
        products_list: [],
      };
      let total = 0;
      for (let product in productsList) {
        product = productsList[product];
        let _total = ((100 - this.totalDiscountPercent) * product.total) / 100;
        _total = Math.ceil(_total);
        data.products_list.push({
          id: product.id,
          quantity: product.quantity,
          total: _total,
        });

        total += _total;
      }
      data.total = total;
      this.totalAmount = total;
      this.totalDiscountAmount = before - total;

      this.totalTabDiscount1 = this.getAmount(
        totals[1]["amount"],
        totals[1]["before"],
        totals[1]["count"]
      );
      this.totalTabDiscount1 = Math.ceil(
        ((totals[1]["before"] - this.totalTabDiscount1) * 100) /
          totals[1]["before"]
      );
      this.totalTabDiscount2 = this.getAmount(
        totals[2]["amount"],
        totals[2]["before"],
        totals[2]["count"]
      );
      this.totalTabDiscount2 = Math.ceil(
        ((totals[2]["before"] - this.totalTabDiscount2) * 100) /
          totals[2]["before"]
      );
      this.totalTabDiscount3 = this.getAmount(
        totals[3]["amount"],
        totals[3]["before"],
        totals[3]["count"]
      );
      this.totalTabDiscount3 = Math.ceil(
        ((totals[3]["before"] - this.totalTabDiscount3) * 100) /
          totals[3]["before"]
      );
      this.saveCalculation(data);
    },
    async saveCalculation(payload) {
      try {
        await api.profile.postQuestionnaireAttemptCalculation(
          this.attempt.id,
          payload
        );
      } catch (error) {
        console.log(error);
      }
    },
    nextOnCheckout() {
      window.location.href = "/checkout?questionnaire=" + this.slug;
    },
    getAmount(amount, before, count) {
      let discount1 = 0;
      if (before > 30000) discount1 = this.recipeDetail["discount_amount_1"];
      if (before > 50000) discount1 = this.recipeDetail["discount_amount_2"];
      if (before > 70000) discount1 = this.recipeDetail["discount_amount_3"];
      if (before > 100000) discount1 = this.recipeDetail["discount_amount_4"];

      let discount2 = 0;
      if (count > 2) discount2 = this.recipeDetail["discount_packaging_2"];
      if (count > 3) discount2 = this.recipeDetail["discount_packaging_3"];
      if (count > 4) discount2 = this.recipeDetail["discount_packaging_4"];
      if (count > 5) discount2 = this.recipeDetail["discount_packaging_5"];
      if (count > 6) discount2 = this.recipeDetail["discount_packaging_6"];
      if (count > 7) discount2 = this.recipeDetail["discount_packaging_7"];
      let discount = 100 - discount1 - discount2;
      if (discount !== 100) {
        amount = Math.ceil((discount * before) / 100);
      }
      return amount;
    },
    getPeriodDeclination(product, period) {
      let value = this.getDeclination(
        period.number_of_capsules,
        product.product.release_form
      );
      let width = Number(period.width.split("px")[0]);
      if (width < 100 && value.length > 4) {
        value = value.slice(0, 3);
        value += ".";
      }
      return value;
    },
  },
};
</script>
