import api from "@/api";
import Locale from "@/api/locale";

export default {
  data() {
    return {
      authText: "",
    };
  },
  methods: {
    formatPrice(value) {
      value = new Intl.NumberFormat("ru").format(value);
      value = value.replace(",", ".");
      if (value.indexOf(".") !== -1) {
        if (value.split(".")[1].length === 1) value = value + "0";
      }
      return value;
    },
    getStars(value) {
      if (value > 4.45) {
        return "<span class='full'></span><span class='full'></span><span class='full'></span><span class='full'></span><span class='full'></span>";
      }
      if (value > 3.45) {
        return "<span class='full'></span><span class='full'></span><span class='full'></span><span class='full'></span><span class='empty'></span>";
      }
      if (value > 2.45) {
        return "<span class='full'></span><span class='full'></span><span class='full'></span><span class='empty'></span><span class='empty'></span>";
      }
      if (value > 1.45) {
        return "<span class='full'></span><span class='full'></span><span class='empty'></span><span class='empty'></span><span class='empty'></span>";
      }
      if (value > 0.45) {
        return "<span class='full'></span><span class='empty'></span><span class='empty'></span><span class='empty'></span><span class='empty'></span>";
      }

      return "<span class='empty'></span><span class='empty'></span><span class='empty'></span><span class='empty'></span><span class='empty'></span>";
    },
    async clickFeedbackLike(item) {
      if (item.isMy) {
        this.errorTooltip = "like";
        return true;
      }
      if (this.authToken) {
        try {
          const result = await api.shop.putShopProductReviewUserLike(item.id);
          item.count_likes = result["data"]["count_likes"];
          if (item.isMyLike) item.isMyLike = false;
          else item.isMyLike = true;
        } catch (error) {
          console.log(error);
        }
      } else {
        this.isOpenAuth = true;
        this.authText =
          "Войдите или зарегистрируйтесь,<br /> чтобы оценить отзыв";
      }
    },
    async saveCartToUser(isCart = false) {
      try {
        await api.shop.deleteShopProductUserBasketClear();
      } catch (error) {
        console.log(error);
      }
      let products_remove = [];
      for (let i = 0; i < localStorage.length; i++) {
        let values = localStorage.key(i).split("shop_basketProduct_");
        if (values.length > 1) {
          let quantity = localStorage.getItem(
            "shop_basketProduct_" + values[1]
          );
          let source = localStorage.getItem(
            "shop_basketProductSourcePage_" + values[1]
          );
          let article_id = localStorage.getItem(
            "shop_basketProductArticle_" + values[1]
          );
          try {
            let data = {
              product_id: Number(values[1]),
              quantity: Number(quantity),
            };
            if (source) data.source_page = source;
            if (article_id) data.article_id = article_id;
            let mailingMassId = localStorage.getItem("mailingMassId");
            if (mailingMassId) data.mailing_mass_id = mailingMassId;
            let mailingTriggerId = localStorage.getItem("mailingTriggerId");
            if (mailingTriggerId) data.mailing_trigger_id = mailingTriggerId;
            await api.shop.postShopProductUserBasket(data);
            products_remove.push(values[1]);
          } catch (error) {
            console.log(error);
          }
        }
        for (let item in products_remove) {
          item = products_remove[item];
          localStorage.removeItem("shop_basketProduct_" + item);
          localStorage.removeItem("shop_basketProductSourcePage_" + item);
          localStorage.removeItem("shop_basketProductArticle_" + item);
        }
      }
      if (!isCart) return true;
      this.getCart();
    },
    getDeclination(value, names) {
      const declination = (number, txt, cases = [2, 0, 1, 1, 1, 2]) =>
        txt[
          number % 100 > 4 && number % 100 < 20
            ? 2
            : cases[number % 10 < 5 ? number % 10 : 5]
        ];
      return declination(value, [
        names["singular"],
        names["plural"],
        names["dative"],
      ]);
    },
    async trackerClickBasket(product_id, quantity) {
      if (quantity < 1) return false;
      let clickHash = localStorage.getItem("clickHash");
      let clickAttribution = localStorage.getItem("clickAttribution");
      if (!clickHash || !clickAttribution || quantity < 1) return false;
      clickAttribution = new Date(clickAttribution);
      clickAttribution = new Date().getTime() - clickAttribution.getTime();
      clickAttribution = Math.ceil(Math.abs(clickAttribution));
      clickAttribution = clickAttribution / (1000 * 3600 * 24);
      let attributionType = 5;
      if (sessionStorage.getItem("clickAttributionSession")) {
        attributionType = 1;
      } else if (clickAttribution < 7) attributionType = 2;
      else if (clickAttribution < 30) attributionType = 3;
      else if (clickAttribution < 90) attributionType = 4;
      try {
        let data = {
          click_hash: clickHash,
          product_id: product_id,
          quantity: quantity,
          attribution_type: attributionType,
        };
        await api.shop.postShopTrackerClickBasket(data);
      } catch (error) {
        console.log(error);
      }
    },
    async searchSessionBasket(product_id, quantity) {
      if (quantity < 1) return false;
      let searchSessionHash = sessionStorage.getItem("searchSessionHash");
      if (!searchSessionHash) return false;
      try {
        let data = {
          search_session_hash: searchSessionHash,
          product_id: product_id,
          quantity: quantity,
        };
        await api.shop.postShopSearchSessionBasket(data);
      } catch (error) {
        console.log(error);
      }
    },
    changeImage: function (e, item) {
      if (window.innerWidth > 1050) {
        e.preventDefault();
        if (item.images.length > 1) {
          item.isChangeImage = true;
          setTimeout(function () {
            if (item.isChangeImage) item.firstImage = item.images[1].image;
          }, 1000);
        }
      }
    },
    firstImage: function (item) {
      item.isChangeImage = false;
      item.firstImage = item.images[0].image;
    },
    async getBasketProduct(item) {
      if (this.authToken) {
        try {
          const result = await api.shop.getShopProductUserBasket(item["id"]);
          if (result["data"]["results"].length > 0) {
            item.productBasket = result["data"]["results"][0]["quantity"];
          } else item.productBasket = 0;
        } catch (error) {
          console.log(error);
        }
      } else {
        let data = localStorage.getItem("shop_basketProduct_" + item["id"]);
        if (data) item.productBasket = Number(data);
        else item.productBasket = 0;
      }
    },
    async getFavoriteProduct(item) {
      if (this.authToken) {
        try {
          const result = await api.shop.getShopProductUserFavorite(item["id"]);
          if (result["data"]["results"].length > 0) {
            // item.isDisplayFavorite = true;
            item.isDisplayFavorite = false;
            item.isFavorite = "active";
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    async clickFavoriteProduct(item) {
      if (this.authToken) {
        let clickHash = localStorage.getItem("clickHash");
        let clickAttribution = localStorage.getItem("clickAttribution");
        if (!clickHash) clickHash = null;
        let attributionType = 5;
        if (clickAttribution) {
          clickAttribution = new Date(clickAttribution);
          clickAttribution = new Date().getTime() - clickAttribution.getTime();
          clickAttribution = Math.ceil(Math.abs(clickAttribution));
          clickAttribution = clickAttribution / (1000 * 3600 * 24);
          if (sessionStorage.getItem("clickAttributionSession")) {
            attributionType = 1;
          } else if (clickAttribution < 7) attributionType = 2;
          else if (clickAttribution < 30) attributionType = 3;
          else if (clickAttribution < 90) attributionType = 4;
        }
        try {
          let data = {
            product_id: item["id"],
            attribution_type: attributionType,
          };
          if (clickHash) data["click_hash"] = clickHash;
          if (item.isFavorite) {
            data["type"] = "delete";
            item.isFavorite = null;
          } else {
            data["type"] = "post";
            item.isFavorite = "active";
          }
          await api.shop.postShopProductUserFavorite(data);
        } catch (error) {
          console.log(error);
        }
      } else {
        this.isOpenAuth = item["id"];
        this.authText =
          "Войдите или зарегистрируйтесь,<br /> чтобы сохранить товар";
      }
    },
    async clickBasketProduct(item, quantity, isPlus, source_page, article_id) {
      if (item["type"] === 5 && quantity > item["count_available"]) {
        this.errorTooltip = "available";
        return false;
      }
      if (Locale.LOCALE === "en") {
        window.open(item.link);
        return false;
      }
      if (quantity === 1) {
        try {
          window.ym(81846475, "reachGoal", "add_any_cart", {
            URL: document.location.href,
          });
        } catch (e) {
          console.log(e);
        }
        let price = item["website_price"];
        if (item["discount_price"]) price = item["discount_price"];
        let dataItem = {
          ecommerce: {
            currencyCode: "RUB",
            add: {
              products: [
                {
                  id: String(item["id"]),
                  name: String(item["headline_preview"]),
                  price: price,
                  quantity: 1,
                },
              ],
            },
          },
        };
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(dataItem);
      }
      if (this.authToken) {
        try {
          let data = {
            product_id: item["id"],
            quantity: quantity,
            source_page: source_page,
          };
          if (article_id) data.article_id = article_id;
          let mailingMassId = localStorage.getItem("mailingMassId");
          if (mailingMassId) data.mailing_mass_id = mailingMassId;
          let mailingTriggerId = localStorage.getItem("mailingTriggerId");
          if (mailingTriggerId) data.mailing_trigger_id = mailingTriggerId;
          await api.shop.postShopProductUserBasket(data);
          item.productBasket = quantity;
          if (quantity > 0) this.checkPackageBasket(item["id"]);
        } catch (error) {
          console.log(error);
        }
      } else if (quantity === 0) {
        localStorage.removeItem("shop_basketProduct_" + item["id"]);
        localStorage.removeItem("shop_basketProductSourcePage_" + item["id"]);
        localStorage.removeItem("shop_basketProductArticle_" + item["id"]);
        item.productBasket = 0;
      } else {
        localStorage.setItem("shop_basketProduct_" + item["id"], quantity);
        localStorage.setItem(
          "shop_basketProductSourcePage_" + item["id"],
          source_page
        );
        if (article_id) {
          localStorage.setItem(
            "shop_basketProductArticle_" + item["id"],
            article_id
          );
        }
        item.productBasket = quantity;
        this.checkPackageBasket(item["id"]);
      }
      this.trackerClickBasket(item["id"], quantity);
      this.searchSessionBasket(item["id"], quantity);
      this.productStatistics(item["id"], false, true);
      let basket = document.querySelector("header .basket-button span");
      let basket_mobile = document.querySelector("nav .basket .count");
      let countBasket = Number(basket.innerHTML);
      if (isPlus) countBasket += 1;
      else countBasket -= 1;
      basket.innerHTML = countBasket;
      if (!basket_mobile) {
        let basket_mobile = document.createElement("span");
        basket_mobile.setAttribute("class", "count");
        basket_mobile.innerHTML = countBasket;
        document.querySelector("nav .basket").appendChild(basket_mobile);
      } else basket_mobile.innerHTML = countBasket;
    },
    async checkPackageBasket(productId) {
      try {
        const result = await api.shop.getCheckPackageBasket(productId);
        if (result["data"]["is_package"]) {
          this.addPackageBasket();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async addPackageBasket() {
      let productId = 102;
      if (this.authToken) {
        try {
          let data = {
            product_id: productId,
            quantity: 1,
          };
          let mailingMassId = localStorage.getItem("mailingMassId");
          if (mailingMassId) data.mailing_mass_id = mailingMassId;
          let mailingTriggerId = localStorage.getItem("mailingTriggerId");
          if (mailingTriggerId) data.mailing_trigger_id = mailingTriggerId;
          await api.shop.postShopProductUserBasket(data);
        } catch (error) {
          console.log(error);
        }
      } else {
        localStorage.setItem("shop_basketProduct_" + productId, 1);
      }
    },
    openAddQuestionBlock() {
      if (this.authToken) {
        if (this.profile.firstName) {
          this.isOpenAddQuestion = true;
        } else this.isOpenProfileName = true;
      } else {
        this.isOpenAuth = true;
        this.authText =
          "Войдите или зарегистрируйтесь,<br /> чтобы задать вопрос";
      }
    },
    async openAddFeedbackBlock() {
      if (this.authToken) {
        if (this.profile.firstName) {
          if (this.isExistFeedback) this.isOpenExistFeedback = true;
          else {
            try {
              const result = await api.shop.getIsOrdersOnProduct(
                this.product.id
              );
              if (result["data"]["is_exist"]) {
                this.isOpenAddFeedback = true;
              } else this.isOpenNotOrder = true;
            } catch (error) {
              this.isOpenNotOrder = true;
            }
          }
        } else this.isOpenProfileName = true;
      } else {
        this.isOpenAuth = true;
        this.authText =
          "Войдите или зарегистрируйтесь,<br /> чтобы оставить отзыв";
      }
    },
    getTextBonus(value) {
      let result = value.toString();
      if (value > 4 && value < 21) {
        result = "бонусов";
      } else if (result.slice(-1) === "1") {
        result = "бонус";
      } else if (result.slice(-1) === "2") {
        result = "бонуса";
      } else if (result.slice(-1) === "3") {
        result = "бонуса";
      } else if (result.slice(-1) === "4") {
        result = "бонуса";
      } else result = "бонусов";
      return result;
    },
    async productStatistics(product_id, is_view, is_basket) {
      let words = [
        "facebookexternalhit",
        "vkshare",
        "rambler",
        "bot",
        "aport",
        "yahoo",
        "turtle",
        "mail.ru",
        "omsktele",
        "picsearch",
        "sape_context",
        "alexa.com",
        "megadownload.net",
        "askpeter.info",
        "igde.ru",
        "ask.com",
        "yanga.co.uk",
        "scoutjet",
        "similarpages",
        "shrinktheweb.com",
        "followsite.com",
        "dataparksearch",
        "google-sitemaps",
        "appEngine-google",
        "feedfetcher-google",
        "liveinternet.ru",
        "xml-sitemaps.com",
        "agama",
        "metadatalabs.com",
        "h1.hrn.ru",
        "googlealert.com",
        "seo-rus.com",
        "yandeg",
        "yandex",
        "yandexSomething",
        "copyscape.com",
        "domaintools.com",
        "nigma.ru",
        "bing.com",
        "dotnetdotcom",
        "chrome-lighthouse",
      ];

      let isError = false;
      let userAgent = window.navigator.userAgent;
      console.log(userAgent);
      for (let item in words) {
        if (userAgent.indexOf(words[item]) >= 0) {
          isError = true;
          break;
        }
      }
      if (isError) return false;
      let productBasket = localStorage.getItem(
        "statisticsProductBasket" + product_id
      );
      if (productBasket && is_basket) return false;
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      let clickHash = localStorage.getItem("clickHash");
      if (!clickHash) clickHash = null;
      let productView = localStorage.getItem(
        "statisticsProductView" + product_id
      );
      let is_unique = true;
      if (productView) is_unique = false;
      let payload = {
        product_id: product_id,
        is_view: is_view,
        is_basket: is_basket,
        click_hash: clickHash,
        is_unique: is_unique,
        page_url: location.href,
        device: "desktop",
        operating_system: "unknown",
        screen_size: window.screen.width + "x" + window.screen.height,
        utm_source: urlParams.get("utm_source"),
        utm_medium: urlParams.get("utm_medium"),
        utm_campaign: urlParams.get("utm_campaign"),
        utm_content: urlParams.get("utm_content"),
        utm_term: urlParams.get("utm_term"),
      };
      if (/Mobi/.test(userAgent)) payload.device = "mobile";
      if (userAgent.indexOf("Windows NT 10.0") !== -1) {
        payload.operating_system = "Windows 10";
      }
      if (userAgent.indexOf("Windows NT 6.3") !== -1) {
        payload.operating_system = "Windows 8.1";
      }
      if (userAgent.indexOf("Windows NT 6.2") !== -1) {
        payload.operating_system = "Windows 8";
      }
      if (userAgent.indexOf("Windows NT 6.1") !== -1) {
        payload.operating_system = "Windows 7";
      }
      if (userAgent.indexOf("Windows NT 6.0") !== -1) {
        payload.operating_system = "Windows Vista";
      }
      if (userAgent.indexOf("Windows NT 5.1") !== -1) {
        payload.operating_system = "Windows XP";
      }
      if (userAgent.indexOf("Windows NT 5.0") !== -1) {
        payload.operating_system = "Windows 2000";
      }
      if (userAgent.indexOf("Mac") !== -1) payload.operating_system = "Mac iOS";
      if (userAgent.indexOf("X11") !== -1) payload.operating_system = "UNIX";
      if (userAgent.indexOf("Linux") !== -1) payload.operating_system = "Linux";
      try {
        const result = await api.shop.postShopProductStatistics(payload);
        if (result["data"]["view_hash"]) {
          localStorage.setItem(
            "statisticsProductView" + product_id,
            result["data"]["view_hash"]
          );
        }
        if (result["data"]["basket_hash"]) {
          localStorage.setItem(
            "statisticsProductBasket" + product_id,
            result["data"]["basket_hash"]
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
