<template>
  <div class="personal-header">
    <HeaderBlock :isHideSearch="false" />
  </div>
  <section class="personal-section">
    <PersonalLeftBlock :hrefTo="'loyalty_program'" />
    <div class="block-right">
      <a @click="$router.push({ name: 'personal' })" class="back">
        {{ backProfile }}
      </a>
      <h1>Программа лояльности</h1>
      <div class="lp-balance">
        <span>{{ formatPrice(lpBalance) }}</span>
        <span>
          Бонусов<br />
          доступно
        </span>
      </div>
      <div v-if="lpStories.length > 0" class="lp-stories">
        <div class="lp-stories-items">
          <div class="header">
            <span>Дата</span>
            <span>Операция</span>
            <span>Бонусы</span>
          </div>
          <div v-for="item in lpStories" :key="item" class="item">
            <span>{{ dateFormatString(item.created_at) }}</span>
            <span v-html="item.comment"></span>
            <span v-if="item.type === 1">+ {{ item.amount }}</span>
            <span v-else class="write-off">- {{ item.amount }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="personal-footer">
    <FooterBlock />
  </div>
</template>

<script>
import jsMixin from "@/mixins/jsMixin";
import mainMixin from "@/mixins/mainMixin";
import profileMixin from "@/mixins/profileMixin";
import pageMixin from "@/mixins/pageMixin";
import productMixin from "@/mixins/productMixin";
import HeaderBlock from "@/components/HeaderBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import PersonalLeftBlock from "@/components/PersonalLeftBlock.vue";
import "@/assets/css/personal.css";
import Locale from "@/api/locale";
import api from "@/api";

export default {
  mixins: [jsMixin, mainMixin, profileMixin, pageMixin, productMixin],
  components: {
    HeaderBlock,
    FooterBlock,
    PersonalLeftBlock,
  },
  data() {
    return {
      backProfile: Locale.TEXTS.backProfile,
      lpBalance: 0,
      lpStories: [],
    };
  },
  async created() {
    document.title = Locale.TEXTS.profileTitle;
    try {
      const result = await api.profile.getUserBonus();
      this.lpBalance = result["data"]["results"][0]["lp_balance"];
      for (let item in result["data"]["results"][0]["lp_stories"]) {
        item = result["data"]["results"][0]["lp_stories"][item];
        let comment = item["comment"].replace(
          "[order_id]",
          "<a target='_blank' href='/personal/orders?open_order_id=" +
            item["order_id"] +
            "'>" +
            item["order_id"] +
            "</a>"
        );
        this.lpStories.push({
          created_at: item["created_at"],
          type: item["type"],
          amount: item["amount"],
          comment: comment,
        });
      }
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
