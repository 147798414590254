<template>
  <div class="personal-header">
    <HeaderBlock :isHideSearch="false" />
  </div>
  <section class="personal-section">
    <PersonalLeftBlock :hrefTo="'subscribers'" />
    <div class="block-right personal-products">
      <a @click="$router.push({ name: 'personal' })" class="back">В профиль</a>
      <h1>Уведомления</h1>
      <div class="subscriber-item" v-if="profile.email">
        <div>
          <span>Уведомления на e-mail</span>
          <span>{{ profile.email }}</span>
        </div>
        <button
          class="not"
          v-if="isEmailSubscriber"
          @click.stop="updateSubscriber(false, isSmsSubscriber)"
        >
          Отключить
        </button>
        <button v-else @click.stop="updateSubscriber(true, isSmsSubscriber)">
          Подключить
        </button>
      </div>
      <div class="subscriber-item">
        <div>
          <span>Уведомления по смс</span>
          <span>{{ profile.phone }}</span>
        </div>
        <button
          class="not"
          v-if="isSmsSubscriber"
          @click.stop="updateSubscriber(isEmailSubscriber, false)"
        >
          Отключить
        </button>
        <button v-else @click.stop="updateSubscriber(isEmailSubscriber, true)">
          Подключить
        </button>
      </div>
    </div>
  </section>
  <div class="personal-footer">
    <FooterBlock />
  </div>
</template>

<script>
import jsMixin from "@/mixins/jsMixin";
import mainMixin from "@/mixins/mainMixin";
import profileMixin from "@/mixins/profileMixin";
import HeaderBlock from "@/components/HeaderBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import PersonalLeftBlock from "@/components/PersonalLeftBlock.vue";
import "@/assets/css/personal.css";
import api from "@/api";

export default {
  mixins: [jsMixin, mainMixin, profileMixin],
  components: {
    HeaderBlock,
    FooterBlock,
    PersonalLeftBlock,
  },
  data() {
    return {
      isEmailSubscriber: false,
      isSmsSubscriber: false,
    };
  },
  async created() {
    document.title = "Уведомления";
    try {
      let result = await api.profile.getUserSubscribers();
      result = result["data"]["results"][0];
      this.isEmailSubscriber = result["is_email_subscriber"];
      this.isSmsSubscriber = result["is_sms_subscriber"];
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async updateSubscriber(email, sms) {
      this.isEmailSubscriber = email;
      this.isSmsSubscriber = sms;
      let payload = {
        is_email_subscriber: email,
        is_sms_subscriber: sms,
      };
      try {
        await api.profile.putUserSubscribers(this.profile.id, payload);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
