import api from "@/api";

export default {
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    let streamUrl = urlParams.get("t");
    if (streamUrl) this.getTrackerClick(urlParams, streamUrl, false);
    setTimeout(function () {
      let isScroll25, isScroll50, isScroll75, isScroll100;
      let heightY = document.documentElement.scrollHeight;
      let scroll25 = (25 * heightY) / 100;
      let scroll50 = (50 * heightY) / 100;
      let scroll75 = (75 * heightY) / 100;
      let scroll100 = heightY;
      window.addEventListener("scroll", function () {
        let scrollY = window.innerHeight + window.scrollY;
        if (scrollY >= scroll25 && !isScroll25) {
          isScroll25 = true;
          try {
            window.ym(81846475, "reachGoal", "25");
            isScroll25 = true;
          } catch (e) {
            console.log(e);
          }
        }
        if (scrollY >= scroll50 && !isScroll50) {
          isScroll50 = true;
          try {
            window.ym(81846475, "reachGoal", "50");
            isScroll25 = true;
          } catch (e) {
            console.log(e);
          }
        }
        if (scrollY >= scroll75 && !isScroll75) {
          isScroll75 = true;
          try {
            window.ym(81846475, "reachGoal", "75");
            isScroll25 = true;
          } catch (e) {
            console.log(e);
          }
        }
        if (scrollY >= scroll100 && !isScroll100) {
          isScroll100 = true;
          try {
            window.ym(81846475, "reachGoal", "100");
            isScroll25 = true;
          } catch (e) {
            console.log(e);
          }
        }
      });
    }, 1000);
  },
  methods: {
    async getTrackerClick(urlParams, value, isRedirect) {
      let trackerHash = localStorage.getItem("trackerHash");
      if (trackerHash === value) return false;
      let userAgent = window.navigator.userAgent;
      let userHash = localStorage.getItem("userHash");
      if (!userHash) userHash = null;
      localStorage.removeItem("cart_promoCode");
      localStorage.removeItem("tracker_promoCode");
      let payload = {
        user_hash: userHash,
        stream_url: value,
        browser: "unknown",
        referrer: document.referrer,
        device: "desktop",
        http_user_agent: userAgent,
        operating_system: "unknown",
        timezone: new Date().getTimezoneOffset(),
        language: window.navigator.userLanguage || window.navigator.language,
        screen_size: window.screen.width + "x" + window.screen.height,
        connection_type: "unknown",
        utm_source: urlParams.get("utm_source"),
        utm_medium: urlParams.get("utm_medium"),
        utm_campaign: urlParams.get("utm_campaign"),
        utm_content: urlParams.get("utm_content"),
        utm_term: urlParams.get("utm_term"),
        price: urlParams.get("price"),
      };
      if (window.navigator.connection) {
        switch (navigator.connection.type) {
          case navigator.connection.WIFI:
            payload.connection_type = "wifi";
            break;
          case navigator.connection.ETHERNET:
            payload.connection_type = "ethernet";
            break;
          case navigator.connection.CELL_2G:
            payload.connection_type = "cell 2G";
            break;
          case navigator.connection.CELL_3G:
            payload.connection_type = "cell 3G";
            break;
          default:
            payload.connection_type = "Missing";
        }
      }
      if (userAgent.indexOf("Opera") !== -1) payload.browser = "Opera";
      else if (userAgent.indexOf("OPR") !== -1) payload.browser = "Yandex";
      else if (userAgent.indexOf("YaBrowser") !== -1) {
        payload.browser = "Yandex";
      } else if (userAgent.indexOf("Yowser") !== -1) payload.browser = "Yandex";
      else if (userAgent.indexOf("Chrome") !== -1) payload.browser = "Chrome";
      else if (userAgent.indexOf("Safari") !== -1) payload.browser = "Safari";
      else if (userAgent.indexOf("Firefox") !== -1) payload.browser = "Firefox";
      else if (
        userAgent.indexOf("MSIE") !== -1 ||
        !!document.documentMode === true
      ) {
        payload.browser = "IE";
      }
      if (/Mobi/.test(userAgent)) payload.device = "mobile";
      if (userAgent.indexOf("Windows NT 10.0") !== -1) {
        payload.operating_system = "Windows 10";
      }
      if (userAgent.indexOf("Windows NT 6.3") !== -1) {
        payload.operating_system = "Windows 8.1";
      }
      if (userAgent.indexOf("Windows NT 6.2") !== -1) {
        payload.operating_system = "Windows 8";
      }
      if (userAgent.indexOf("Windows NT 6.1") !== -1) {
        payload.operating_system = "Windows 7";
      }
      if (userAgent.indexOf("Windows NT 6.0") !== -1) {
        payload.operating_system = "Windows Vista";
      }
      if (userAgent.indexOf("Windows NT 5.1") !== -1) {
        payload.operating_system = "Windows XP";
      }
      if (userAgent.indexOf("Windows NT 5.0") !== -1) {
        payload.operating_system = "Windows 2000";
      }
      if (userAgent.indexOf("Mac") !== -1) payload.operating_system = "Mac iOS";
      if (userAgent.indexOf("X11") !== -1) payload.operating_system = "UNIX";
      if (userAgent.indexOf("Linux") !== -1) payload.operating_system = "Linux";
      try {
        const result = await api.shop.postShopTrackerClick(payload);
        localStorage.setItem("trackerHash", value);
        localStorage.setItem("clickHash", result["data"]["click_hash"]);
        localStorage.setItem("userHash", result["data"]["user_hash"]);
        if (result["data"]["promo_code"]) {
          localStorage.setItem(
            "tracker_promoCode",
            result["data"]["promo_code"]
          );
        }
        localStorage.setItem("clickAttribution", String(new Date()));
        sessionStorage.setItem("clickAttributionSession", "1");
        let redirectUrl = "/";
        if (result["data"]["redirect_url"]) {
          redirectUrl = "/" + result["data"]["redirect_url"];
        }
        redirectUrl += "?click_hash=" + result["data"]["click_hash"];
        redirectUrl += "&user_hash=" + result["data"]["user_hash"];
        if (payload.utm_source) {
          redirectUrl += "&utm_source=" + payload.utm_source;
        }
        if (payload.utm_medium) {
          redirectUrl += "&utm_medium=" + payload.utm_medium;
        }
        if (payload.utm_campaign) {
          redirectUrl += "&utm_campaign=" + payload.utm_campaign;
        }
        if (payload.utm_content) {
          redirectUrl += "&utm_content=" + payload.utm_content;
        }
        if (payload.utm_term) redirectUrl += "&utm_term=" + payload.utm_term;
        if (isRedirect) window.location.href = redirectUrl;
        else location.reload();
      } catch (error) {
        console.log(error);
        window.location.href = "/";
      }
    },
  },
};
